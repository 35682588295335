<template>
  <div>
    <div class="pay_bag">
      <img src="@/assets/images/stockCar/succeed.png">
      <div class="title">支付成功，VIP店铺申请已提交审核!</div>
      <div class="dise">您的店铺升级操作已提交，通过后会以消息的形式通知您！!</div>
      <el-button class="m-t-100" plain @click="$oucy.back()">返 回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "pay",
  data(){
    return{}
  },
  mounted() {
  },
  methods:{}
}
</script>

<style scoped>
.pay_bag{
  width: 100%;
  height: 800px;
  background-image: url('../../../../assets/pay_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.title{
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 26px;
}
.dise{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #A5ACC0;
  margin-top: 16px;
}
</style>